import * as React from "react"
import { ChevronLeft, ChevronRight, MoreHorizontal } from "lucide-react"

import { cn } from "@/lib/utils"
import { buttonVariants } from "@/Components/ui/button";

const Pagination = ({
    className,
    ...props
}) => (
    <nav
        role="navigation"
        aria-label="pagination"
        className={cn("flex flex-nowrap w-full items-center justify-center", className)}
        {...props} />
)
Pagination.displayName = "Pagination"

const PaginationContent = React.forwardRef(({ className, ...props }, ref) => (
    <ul
        ref={ref}
        className={cn("flex flex-row items-center rounded gap-1", className)}
        {...props} />
))
PaginationContent.displayName = "PaginationContent"

const PaginationItem = React.forwardRef(({ className, ...props }, ref) => (
    <li ref={ref} className={cn("rounded-full", className)} {...props} />
))
PaginationItem.displayName = "PaginationItem"

const PaginationLink = ({
    className,
    isActive,
    size = "icon",
    dense = false,
    ...props
}) => (
    <a
        aria-current={isActive ? "page" : undefined}
        className={cn(
            buttonVariants({
                variant: isActive ? "outline" : "ghost",
                size: dense ? 'xs' : size,
            }),
            dense && "!px-1 py-1 text-sm",
            className
        )}
        {...props}
    />
)

PaginationLink.displayName = "PaginationLink"

const PaginationPrevious = ({
    className,
    dense = true,
    ...props
}) => (
    <PaginationLink
        aria-label="Go to previous page"
        size="default"
        className={cn("gap-1 pl-2.5 group rounded-full", className)}
        {...props}>
        <ChevronLeft className="h-4 w-4 group-disabled:text-gray-200 text-primary" />
        {!dense && <span className="md:inline hidden">Previous</span>}
    </PaginationLink>
)
PaginationPrevious.displayName = "PaginationPrevious"

const PaginationNext = ({
    className,
    dense = true,
    ...props
}) => (
    <PaginationLink
        aria-label="Go to next page"
        size="default"
        className={cn("gap-1 pr-2.5 group rounded-full", className)}
        {...props}>
        {!dense && <span className="md:inline hidden">Next</span>}
        <ChevronRight className="h-4 w-4 group-disabled:text-gray-200 text-primary" />
    </PaginationLink>
)
PaginationNext.displayName = "PaginationNext"

const PaginationEllipsis = ({
    className,
    ...props
}) => (
    <span
        aria-hidden
        className={cn("flex h-9 w-9 rounded-full items-center justify-center", className)}
        {...props}>
        <MoreHorizontal className="h-4 w-4" />
        <span className="sr-only">More pages</span>
    </span>
)
PaginationEllipsis.displayName = "PaginationEllipsis"

export {
    Pagination,
    PaginationContent,
    PaginationEllipsis,
    PaginationItem,
    PaginationLink,
    PaginationNext,
    PaginationPrevious,
}
