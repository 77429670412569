import React, { useCallback } from "react";
import {
    Pagination,
    PaginationContent,
    PaginationItem,
    PaginationLink,
    PaginationNext,
    PaginationPrevious,
} from "@/Components/ui/pagination";
import { cn } from "@/lib/utils";

const commonItemClass = "bg-cyan-600 text-primary opacity-50 cursor-not-allowed";

const LaravelPagination = React.memo(({ links, dense = false, navigate, returnUrl = true, maxItem = undefined, ...rest }) => {
    const handleNavigate = useCallback((url) => {
        if (!url) return;

        if (returnUrl) {
            navigate(url);
        } else {
            const pageNumber = new URL(url).searchParams.get('page');
            navigate(pageNumber);
        }
    }, [navigate, returnUrl]);


    const renderPaginationItem = (item, index) => {
        const isDisabled = !item.url || item.active;
        const itemClass = cn(
            item.active ? commonItemClass : "bg-transparent",
            isDisabled ? 'pointer-events-none' : ''
        );

        if (index === 0) {
            return (
                <PaginationItem key={`prev-${item.label}`} className={itemClass}>
                    <PaginationPrevious
                        href="#" size="sm" className="aspect-square"
                        onClick={() => handleNavigate(item.url)}
                    />
                </PaginationItem>
            );
        }

        if (index === links.length - 1) {
            return (
                <PaginationItem key={`next-${item.label}`} className={itemClass}>
                    <PaginationNext
                        href="#" size="sm" className="aspect-square"
                        onClick={() => handleNavigate(item.url)}
                    />
                </PaginationItem>
            );
        }

        return (
            <PaginationItem key={`page-${item.label}`} className={itemClass}>
                <PaginationLink size="sm" className="aspect-square"
                    href="#" dense={dense}
                    onClick={() => handleNavigate(item.url)}
                >
                    {item.label}
                </PaginationLink>
            </PaginationItem>
        );
    };

    return (
        <Pagination {...rest}>
            <PaginationContent className="py-1">
                {links?.map(renderPaginationItem)}
            </PaginationContent>
        </Pagination>
    );
});

LaravelPagination.displayName = "LaravelPagination";

export { LaravelPagination };
